// PageMeta.js
import React from 'react';
import { Helmet } from 'react-helmet';

const PageMeta = ({ title, description, url, image }) => (
  <Helmet>
    {/* Primary Meta Tags */}
    <title>{title}</title>
    <meta name="title" content={title} />
    <meta name="description" content={description} />

    {/* Open Graph / Facebook */}
    <meta property="og:type" content="website" />
    <meta property="og:url" content={url} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />

    {/* Twitter */}
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content={url} />
    <meta property="twitter:title" content={title} />
    <meta property="twitter:description" content={description} />
    <meta property="twitter:image" content={image} />

    {/* Schema.org for Google */}
    <script type="application/ld+json">
      {`
      {
        "@context": "http://schema.org",
        "@type": "Event",
        "name": "${title}",
        "description": "${description}",
        "startDate": "2024-10-21",
        "endDate": "2024-10-25",
        "eventAttendanceMode": "http://schema.org/OfflineEventAttendanceMode",
        "eventStatus": "http://schema.org/EventScheduled",
        "location": {
          "@type": "Place",
          "name": "Seattle, Washington USA",
          "address": {
            "@type": "PostalAddress",
            "addressLocality": "Seattle",
            "addressRegion": "WA",
            "addressCountry": "US"
          }
        },
        "image": "${image}",
        "organizer": {
          "@type": "Organization",
          "name": "Virtual Experience Research Accelerator (VERA)",
          "url": "${url}"
        }
      }
      `}
    </script>
  </Helmet>
);

export default PageMeta;
