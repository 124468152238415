import React from 'react';
// import ProfileCard from './ProfileCard';

const BlogDetails = () => {
  return (
    <>
      <section className='blog-details ptb-100'>
        <div className='container'>
          <div className='row justify-content-between'>
            <div className='col-lg-12 pe-5'>
              <div className='blog-details-wrap'>
                <h1>CFP: Tutorial on Participating in the Testing of the Virtual Experience Research Accelerator (VERA)</h1>
                <p>
                  <strong>To be held in conjunction with the 23rd IEEE International Symposium on Mixed and Augmented Reality (ISMAR)</strong>
                  <br />
                  October 21-25 | Seattle, Washington USA
                </p>
                <p>
                  <b>This website will be updated with additional information as ISMAR nears.</b>
                </p>

                <h2>Topic</h2>
                <p>
                  Supported by five collaborative grants from the U.S. National Science Foundation, the Virtual Experience Research Accelerator (VERA) project is developing a human-machine system for carrying out human subjects research related to extended reality (XR), including Virtual Reality and Augmented Reality, for the XR researcher community. The project also seeks to foster a diverse and vibrant VERA research community, and to foster the professional growth of individual researchers.
                </p>
                <p>
                  VERA is currently undergoing iterative development, but by ISMAR 2024 we expect a version will be available for system-level testing, i.e. the running of real or realistic user studies for the purpose of testing the VERA systems and procedures. This tutorial will introduce attendees to the requirements and procedures for getting involved in such system-level testing, expectations for tester effort, and expectations for study results.
                </p>

                <h2>Organizers</h2>
                <ul>
                  <li>Ali Haskins Lisle, Ph.D., VERA Director of Development & Operations, University of Central Florida, ahaskins@ucf.edu</li>
                  <li>Greg Welch, Ph.D., Pegasus Professor and AdventHealth Endowed Chair in Simulation, University of Central Florida, welch@ucf.edu</li>
                </ul>

                <h2>Presenters</h2>
                <ul>
                  <li>Gerd Bruder, Ph.D., Research Associate Professor, University of Central Florida</li>
                  <li>Ali Haskins Lisle, Ph.D., VERA Director of Development & Operations, University of Central Florida</li>
                  <li>John Murray, Ph.D., Associate Professor, University of Central Florida</li>
                  <li>Tabitha Peck, Ph.D., Associate Professor, Davidson College</li>
                  <li>Greg Welch, Ph.D., Pegasus Professor and AdventHealth Endowed Chair in Simulation, University of Central Florida</li>
                </ul>

                <h2>Schedule</h2>

                <h3>Introduction to VERA (30 minutes)</h3>
                <ul>
                  <li><strong>Motivation</strong>
                    <ul>
                      <li>Discuss the limitations of traditional lab-based studies in XR research, such as small, homogenous sample sizes and logistical challenges.</li>
                      <li>Explain how VERA addresses some of these limitations by providing access to a large, diverse participant pool for remote XR experiments.</li>
                      <li>Highlight the potential impact of VERA on advancing XR research and fostering innovation in the field.</li>
                    </ul>
                  </li>
                  <li><strong>Infrastructure Overview</strong>
                    <ul>
                      <li>Provide context on NSF Infrastructure Grant.</li>
                      <li>Provide an overview of the VERA platform, including its architecture and key components.</li>
                      <li>Discuss the technology stack used to build VERA, such as the VERA App, API, frontend, Unity plugin, and database.</li>
                      <li>Highlight the capabilities of each component and how they contribute to the overall functionality of VERA.</li>
                    </ul>
                  </li>
                </ul>

                <h3>A Tour of VERA (30 minutes)</h3>
                <ul>
                  <li><strong>Users and Community</strong>
                    <ul>
                      <li>Introduce the various user roles within the VERA ecosystem, including researchers, participants, and administrators.</li>
                      <li>Discuss the importance of community engagement and mentorship within the VERA community.</li>
                      <li>Highlight how users can collaborate within VERA.</li>
                    </ul>
                  </li>
                  <li><strong>Software and Processes</strong>
                    <ul>
                      <li>Discuss the user permissions models within VERA, including access levels for researchers (e.g., faculty and students), individuals in the VERA participant pool, and VERA administrators.</li>
                      <li>Provide guidance on navigating IRB approvals and institutional agreements when using VERA for research.</li>
                      <li>Walk through the processes involved in conducting a study using VERA, from study creation to participant recruitment and data collection.</li>
                    </ul>
                  </li>
                </ul>

                <h3>Testing VERA (2.5 hours, with added breaks)</h3>
                <ul>
                  <li><strong>Overview of the Roles, Resources, and Responsibilities</strong>
                    <ul>
                      <li>Discussion of testing in teams — teams (as opposed to lone individuals) are encouraged, with a faculty lead investigator.</li>
                      <li>Overview of researchers vs. participant workflows.</li>
                      <li>Overview of the specific resources available to researchers and participants.</li>
                      <li>Review of the potential benefits to the researchers participating in the testing.</li>
                      <li>Review of the expectations for the engagement and efforts of researchers participating in the testing.</li>
                    </ul>
                  </li>
                  <li><strong>Permissions and IRB Processes</strong>
                    <ul>
                      <li>Provide detailed guidance on obtaining permissions for researchers, students, and lab managers to access VERA resources.</li>
                      <li>Discuss best practices for ensuring compliance with IRB regulations when conducting research using VERA, including data privacy and participant consent.</li>
                      <li>Explain the steps involved in establishing institutional agreements with VERA.</li>
                    </ul>
                  </li>
                  <li><strong>Example Experiment Working Session</strong>
                    <ul>
                      <li>Work through multiple examples of studies being run through VERA.</li>
                      <li>Expose known risks and/or unexpected workflow pathways.</li>
                      <li>Address best practices.</li>
                      <li>Provide examples for attendees to work through themselves. (We will attempt to assemble a volunteer group of remote participants for this purpose, or simulate the same.)</li>
                    </ul>
                  </li>
                  <li><strong>Catered Assistance, Questions, Feedback</strong>
                    <ul>
                      <li>Offer personalized assistance to tutorial attendees, addressing any questions or concerns they may have about using VERA for their research projects.</li>
                      <li>Solicit feedback from attendees about VERA and any suggestions for improving the platform.</li>
                      <li>Provide resources for ongoing support and community engagement, such as user forums, documentation, and tutorials.</li>
                    </ul>
                  </li>
                </ul>

                <p>Please reach out with any questions and we will ensure to address them.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetails;
