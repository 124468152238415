import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFoundScreen from "../components/others/NotFoundScreen";
import ScrollToTop from "../components/common/ScrollToTop";

import Blog1 from "../pages/blog/Blog1";


function index() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Blog1 />} />
        <Route exact path="/404" element={<NotFoundScreen />} />
        <Route path="*" element={<NotFoundScreen />} />
      </Routes>
    </BrowserRouter>
  );
}

export default index;
